// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../node_modules/@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("./../node_modules/@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx": () => import("./../node_modules/@narative/gatsby-theme-novela/src/templates/author.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx" */),
  "component---src-templates-api-js": () => import("./../src/templates/api.js" /* webpackChunkName: "component---src-templates-api-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-graphql-adopters-js": () => import("./../src/pages/graphql-adopters.js" /* webpackChunkName: "component---src-pages-graphql-adopters-js" */),
  "component---src-pages-graphql-dashboard-js": () => import("./../src/pages/graphql-dashboard.js" /* webpackChunkName: "component---src-pages-graphql-dashboard-js" */),
  "component---src-pages-graphql-tools-js": () => import("./../src/pages/graphql-tools.js" /* webpackChunkName: "component---src-pages-graphql-tools-js" */),
  "component---src-pages-requested-graphql-apis-js": () => import("./../src/pages/requested-graphql-apis.js" /* webpackChunkName: "component---src-pages-requested-graphql-apis-js" */)
}

